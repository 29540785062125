import config from '../../../public/static/theme/conf.json'

export default {
  initializeThemeAudio({ commit, getters, state, dispatch }) {
      if (getters.isHolidayTheme) {
          commit('INIT_THEME_AUDIO', document.getElementById('themeAudio'))

          if (state.themeAudio && typeof state.themeAudio != 'undefined') {
            state.themeAudio.volume = config.audio.volume;
          }
      }
  },
  stopThemeAudio({ getters, state, dispatch }) {
    if (getters.isHolidayTheme) {
      if (state.themeAudio && typeof state.themeAudio != 'undefined') {
        state.themeAudio.pause()
      }
    }
  },
  getThemePathname({ commit }) {
    commit('GET_PATH_NAME', location.pathname)
  }
}